.accessibilityBar {
  margin-bottom: 0px;
  max-width: 100%;
}

.accessibilityBar.container .navbar {
  padding: 0;
}
.accessibilityBar.container .navbar ul.nav li.nav-item {
  padding: 0 0rem 0 2rem;
}
.accessibilityBar.container .navbar ul.nav li.nav-item a.nav-link {
  font-size: 90%;
  color: #3d3a3a;
  padding: 0;
  text-decoration: underline;
}
.accessibilityBar.container .navbar ul.nav li.nav-item i.fa {
  font-size: 90%;
  padding-right: 0.5rem;
}
.accessibilityBar.container .navbar ul.nav li.nav-item a.nav-link:hover {
  color: #090808;
  text-decoration: underline;
}
.accessibilityBar.container nav ul li {
  border: 0px;
}
