/* Style Startpage Top Container */

.startPageTopContainer img {
  max-width: 100%;
}

.startPageTopContainer .welcomeBox {
  width: 570px;
  height: 286px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  margin-top: -291px;
  left: 53%;
  padding: 18px;
}

.startPageTopContainer .readMore {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 13px;
  background-color: #b0007a;
  width: 100%;
}

.startPageTopContainer .readMore:hover {
  background-color: #c40088;
}
.startPageTopContainer a {
  color: white;
  text-decoration: underline;
}

.startPageTopContainer p {
  margin-bottom: px;
}

/* Style CardListElement */
.cards-startpage {
  height: 100%;
}

/* Style every card in Startpage */
.cards-startpage .card {
  margin: 10px;
  background-color: #efefef;
  max-width: 370px;
}

.cards-startpage .card a {
  color: black;
}

.cards-startpage .card a:hover {
  color: #0564c3;
  text-decoration: underline #3497fa;
}

/* Style CardTextBoxComponent */
.cards-startpage h6 {
  color: #8a8783;
  font-size: 12px;
  margin-bottom: 3px;
}

.cards-startpage .CardTextBoxComponent a {
  color: black;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline #3497fa;
  margin-bottom: 10px;
}

.cards-startpage .CardTextBoxComponent .linktext {
  font-weight: unset;
  display: block;
  margin-top: 8px;
  color: #3497fa;
}

/* Responsive design */
@media (max-width: 1280px) {
  .welcomeBox h1 {
    font-size: 35px;
  }

  .startPageTopContainer .readMore {
    padding-bottom: 9px;
    padding-top: 6px;
  }

  .startPageTopContainer p {
    margin-bottom: 3px;
  }

  .startPageTopContainer .welcomeBox {
    padding-top: 2px;
    padding-bottom: 0px;
    height: 199px;
    margin-top: -210px;
    left: 45%;
  }
}

@media (max-width: 1065px) {
  .startPageTopContainer .welcomeBox {
    height: 199px;
    margin-top: -205px;
    left: 38%;
  }

  .cards-startpage {
    max-width: unset;
  }
}

@media (max-width: 940px) {
  .welcomeBox h1 {
    font-size: 20px;
  }

  .startPageTopContainer .welcomeBox {
    height: 180px;
    left: 25%;
    width: 600px;
    padding: 5px;
    margin-top: -185px;
  }
}

@media (max-width: 770px) {
  .startPageTopContainer .welcomeBox {
    position: unset;
    margin-top: unset;
    background-color: #63a333;
    color: white;
    width: 100%;
    text-align: center;
    height: 100%;
  }

  .startPageTopContainer .readMore {
    background-color: #63a333;
  }

  .cards-startpage {
    flex-direction: column;
  }

  .cards-startpage .card {
    max-width: 100%;
  }
}
