/* Root margin */
main {
  margin-top: 95px;
}

@media (max-width: 360px) {
  main {
    margin-top: unset;
  }
}

/* Card color and margin */
.CardTextBoxComponent .card-title {
  background-color: #b0007a;
  color: white;
  padding: 10px 20px;
  margin-bottom: 0px;
}

/* Images */
img {
  max-width: 100%;
}

/* Links */
a {
  color: #0564c3;
}
a:hover {
  color: black;
  text-decoration: underline;
}
