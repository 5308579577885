header {
  border-bottom: 2px green solid;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.dropdown-menu.show a:hover {
  background-color: transparent;
}

.nav-link.show,
.offcanvasNavbarDropdown-expand-false:hover {
  color: white !important;
}

.navbar-brand {
  margin-left: -100px;
}

@media (max-width: 1060px) {
  .navbar-brand {
    margin-left: 0px;
  }

  header .container {
    margin-left: 10px;
  }
}

@media (max-width: 600px) {
  header .container {
    margin-left: unset;
  }
}

.headerImageLink {
  width: 16px;
  height: 16px;
  margin: 3px;
}

footer ul {
  list-style: none;
}

header img {
  max-width: 300px;
}

@media (max-width: 770px) {
  header img {
    max-width: 200px;
  }
}

@media (max-width: 360px) {
  header {
    position: unset;
    top: unset;
    z-index: 1030;
  }

  header img {
    max-width: 125px;
  }
}
