.newsListContainer {
  padding-top: 15px;
}

.buttonsNewsList {
  padding: 8px;
}

.newsListContainer button {
  background-color: transparent;
  border: none;
  font-size: 18px;
}
.container-lg {
  margin: 0;
  padding: 0;
  column-gap: 20px;
}

.container-lg .col {
  margin: 0;
  margin-left: 6px;
  margin-right: 6px;
  padding: 0;
}

.latestNews {
  padding-top: 16px;
  padding-bottom: 16px;
}
.latestNews:hover {
  text-decoration: underline;
  cursor: pointer;
  background-color: #f9f9f9;
}

main article .row {
  /* border: 1px solid rgb(231, 0, 231); */
}

.newsList {
  row-gap: 16px;
  column-gap: 4px;
}
