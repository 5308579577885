/* TEMPLET */

.templet1 .asaid,
.templet2 .asaid {
  margin-top: 23em;
}

.ingress {
  font-weight: 500;
}

/* TEMPLET 1 */

/* Style Green Top Container */
.templet1 .top {
  width: 100%;
  height: 300px;
  background: #bfe3a5;
  margin-bottom: -275px;
}

.templet1 img {
  width: 100%;
}

@media (max-width: 770px) {
  .templet1 .top {
    display: none;
  }
}

@media (max-width: 770px) {
  .topContainer h1 {
    font-size: 25px;
  }
}

.templet1 h3 {
  font-size: 20px;
}

.templet1 td {
  background-color: #bfe3a5;
  padding: 25px;
}

/* Style Card */
.templet1 .card {
  background-color: #efefef;
}

.templet1 .card-body h6 {
  margin: 0px;
}

.templet1 .CardTextBoxComponent .element {
  margin-top: 10px;
}

.templet1 .card-body {
  padding-top: 6px;
  padding-bottom: 6px;
}

.templet1 .top p {
  font-weight: 600;
  max-width: 700px;
}

/* Style Main Container */

.templet1 .main .mainContainer {
  border: white solid 15px;
  padding: 0px;
}

/* TEMPLET 2 */

.templet2 .content {
  padding-top: 20px;
}

.templet2 .createDate {
  font-style: italic;
}

@media (max-width: 940px) {
  .templet2 h1 {
    font-size: 25px;
  }
}

/* Style Asaid Container */

@media (max-width: 770px) {
  .templet1 .asaid,
  .templet2 .asaid {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .templet1 .content,
  .templet1 .main {
    flex-direction: column;
    max-width: unset;
    padding: 0px;
  }
  .templet2 .content .container {
    max-width: unset;
  }

  .topContainer {
    background: #bfe3a5;
    width: 100vw;
    padding: 15px;
    padding-right: 40px;
  }

  .templet1 .container {
    max-width: unset;
  }
}
