.openPosition .card {
  height: 100%;
  /* cursor: pointer; */
  margin-bottom: 16px;
}

.openPosition h2 a {
  color: black;
  text-decoration: underline #0564c3;
}

.openPosition h2 a:hover {
  color: #0564c3;
}

@media (max-width: 600px) {
  .openPosition h2 {
    font-size: 20px;
  }
}

.card img {
  /* height: 300px; */
}
.openPosition {
  margin-top: 20px;
}
.openPosition ul {
  list-style: disc;
  margin-left: 28px;
}

.newslistpublisheddate {
  font-size: 12px;
  font-family: Verdana;
  color: darkgray;
}
