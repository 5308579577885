footer.footer {
  margin-top: auto;
  padding: 20px 0 80px 0;
  height: 100%;
  width: 100%;
  background-color: #63a333;
}

.footer-img {
  max-height: 50px;
}

footer a {
  color: white;
  text-decoration: underline;
  font-weight: 500;
}

.footerContainer {
  max-width: 1140px;
}

@media (max-width: 1218px) {
  .footerContainerContent {
    flex-direction: column;
  }

  .footerContainerContent ul {
    padding: 0px;
  }
}

@media (max-width: 770px) {
  footer img {
    max-width: 200px;
  }
}
