div.navbar-toggler {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px;
}
.dropdown-menu.show {
  border: 0px;
  background-color: transparent;
}

.offcanvas-end {
  height: 100%;
}
.offcancanvasnav {
  padding: 0px;
  margin: 0px;
}

.navbar-toggler-icon {
  width: 35px;
  height: 35px;
}

.dropdown-button {
  color: white;
  border: white 1px solid;
}

.nav-item a {
  padding-left: 10px;
  color: white;
}
.offcancanvasnav-content .navbar-toggler {
  padding: 6px;
  margin: 4px;
}
.offcancanvasnav-content {
  margin-left: 40px;
  padding-top: 8px;
  padding-right: 0px !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.offcancanvasnav-content .navbar-toggler::after {
  font-size: 15px;
  text-transform: uppercase;
  color: white;
  display: block;
  font-weight: 500;
  content: "Meny";
}

.offcanvas {
  bottom: unset;
}

.navbar-toggler {
  background-color: #63a333;
}
.navbar-toggler a {
  text-decoration: none;
  color: white;
}

.navItem .navbar-toggler {
  margin: 3px;
}
.offcanvas-body,
.offcanvas-header {
  background-color: #63a333;
}
@media (max-width: 1215px) {
  .offcancanvasnav-content {
    max-width: 100%;
  }
}

@media (max-width: 360px) {
  .navbar-toggler-icon {
    width: 25px;
    height: 25px;
  }

  .navbar-toggler::after {
    font-size: 12px;
  }
}
